import React from 'react'
import {Text, Box, Divider, Input, NumberInput, Button} from '@mantine/core'
const Total = ({ subTotal, ivaAmount, total, desc, totalMoneyChange=0 }) =>
{

  return (
    <>
      <Box>

        <Box className='flex flex-row items-center justify-end' p={ 2 }>
          <Text fz="sm" fw="bold" mx="sm">Subtotal</Text>
          <Text fz="lg">$ { subTotal } MXN</Text>
        </Box>

      </Box>

      <Box className='flex flex-row items-center justify-end' p={ 2 }>
        <Text fz="sm" fw="bold" mx="sm">Incluye IVA</Text>
        <Text fz="lg">$ { ivaAmount } MXN</Text>
      </Box>
  
      <Divider my="xs" variant="dashed" />

      <Box className='flex flex-row items-center justify-end' p={ 2 }>
        <Text fz="sm" fw="bold" mx="sm">Total</Text>
        <Text fz="lg">$ { total } MXN </Text>
      </Box>
     
        <Divider my="md" variant="dashed" />

        <Box className='flex flex-row items-center justify-end' p={ 2 }>
          <Text fz="sm" fw="bold" mx="sm">Cambio: </Text>
          <Text className='text-md  mx-2'>${ totalMoneyChange.toFixed(2) } MXN</Text>

        </Box>




    </>
  )

}

export default Total