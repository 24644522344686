import { Table, Text, Badge, Box, Flex } from "@mantine/core";


const OrderRow = ({
  product,
  markAsCourtesy,
}) =>
{
  return (
    <Table.Tr>
      <Table.Td>
        <Box>
          <Box className="flex flex-row items-center justify-between">
            <Text fw="normal" fz="md" className="truncate"> { product.quantity }x{ product.name }</Text>

            <Text fw="bold">
              $ { (product.price * product.quantity).toFixed(2) }
            </Text>
          </Box>

          { product.extras.length !== 0 ? (
            <Box>
              <Text>Extras:</Text>
              { product.extras.map((extra, index) => (
                <Flex justify='space-between' px="sm" my="xs">
                  <Text size="sm">{ extra.quantity } x { extra.name }</Text>
                  <Flex justify={ 'flex-end' } gap="sm">
                    <Text size="sm">$ { (extra.price * extra.quantity).toFixed(2) }</Text>
                  </Flex>
                </Flex>
              )) }
            </Box>
          ) : null }
        </Box>
      </Table.Td>
    </Table.Tr>
  );
};

export default OrderRow;
