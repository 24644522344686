/* eslint-disable import/no-anonymous-default-export */
import
    {
        LIST_SETTINGS
    } from '../types';



export default (state, action) =>
{
    switch (action.type) {

        case LIST_SETTINGS:
            localStorage.setItem('settings', JSON.stringify(action.payload))
            return {
                settings: action.payload,
                loading: false
            }
        default:
            console.log('default')
            return state;

    }
}