import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mantine/core';
import Summary from '../../components/Orders/Order/Summary';
import Total from '../../components/Orders/Order/Total';
import { getProductCartApi, get_order } from '../../utils/apis/orders';
import { getTotal, getIva } from '../../utils/functions/getTotal';
import Loading from '../../components/Loading/Loading';
import { localOrder } from '../../utils/functions/generateLocalCart';
import OrderInfo from '../../components/Orders/Order/OrderInfo.jsx';

const Order = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [reloadCart, setReloadCart] = useState(false);
  const [orderProducts, setOrderProducts] = useState([]);
  const [orderInfo, setOrderInfo] = useState({
    table: '',
    persons: '',
    name: '',
    phone: '',
    pickUpDate: '',
    pickUpTime: '',
    address: ''
  });
  const [subTotal, setSubtotal] = useState(0.00);
  const [ivaAmount, setIvaAmount] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [desc, setDesc] = useState(0);
  const [moneyChange, setMoneyChange] = useState(0);
  const [totalMoneyChange, setTotalMoneyChange] = useState(0);

  const calcSummary = (items, discount = desc) => {
    const orderTotal = getTotal(items);
    const discountFactor = 1 - (Number(discount) / 100);
    const totalAfterDiscount = orderTotal * (discount <= 100 ? discountFactor : 1);
    const calculatedIva = getIva(totalAfterDiscount || 0);

    setSubtotal(totalAfterDiscount.toFixed(2));
    setIvaAmount(calculatedIva);
    setTotal(totalAfterDiscount.toFixed(2));
    setTotalMoneyChange(Number(moneyChange) - totalAfterDiscount);
  }

  const loadCart = async () => {
    const response = await getProductCartApi();
    setReloadCart(false);
    return response;
  }

  const getOrder = async (orderId) => {
    const res = await get_order(orderId);
    setOrderInfo(res.results.orderInfo);
    const items = res.results.orderProducts;
    const order = await localOrder(items);
    setOrderProducts(order);
    calcSummary(items);
  }

  useEffect(() => {
    if (id) {
      getOrder(id);
    } else {
      (async () => {
        const order = await loadCart();
        calcSummary(order);
      })();
    }
  }, [id, reloadCart]);

  const goBack = () => {
    navigate(-1);
  }

  return (
    <Box className='flex flex-col md:flex-row gap-4 my-4 mx-4 items-start justify-center'>
      {/* <OrderInfo orderInfo={orderInfo} />  */}

      {!reloadCart ? (
        <Box className='w-full lg:w-5/12 flex flex-col gap-4'>
          <Summary
            order={orderProducts}
          
            reloadCart={setReloadCart}
            orderProducts={orderProducts}
            setOrderProducts={setOrderProducts}

            setTotalPayment={setTotal}
            getTotal={getTotal}
          />
          <Total
            total={total}
            subTotal={subTotal}
            ivaAmount={ivaAmount}
            desc={desc}
            moneyChange={moneyChange}
            totalMoneyChange={totalMoneyChange}
          />
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
}

export default Order;
