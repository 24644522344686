
import AppRoute from "./routes/AppRoute";
import SettingState from "./context/Setting/settingState";

function App ()
{
  return (

    <SettingState>
      <AppRoute />
    </SettingState>

  );
}

export default App;
