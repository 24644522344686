import React, { useEffect } from 'react'

import { Box, Table, Divider, Text, ScrollArea } from '@mantine/core'
import OrderRow from './OrderRow'
import EmptyOrder from '../../Empties/EmptyOrder'

const Summary = ({
  order,
  orderProducts,
  setOrderProducts,
  loadingProducts,
  getTotal,
  setTotalPayment,
  markAsCourtesy,
}) =>
{
  useEffect(() =>
  {

    (async () =>
    {
      // const productTemp = await localOrder(order)
      setOrderProducts(orderProducts)
      setTotalPayment(getTotal(orderProducts))

    })()
  }, [ orderProducts ]);

  return (

    <Box >
      <div className='flex flex-row justify-between items-center'>
        <Text my="md" fw="bold" fz="lg">Resumen de la orden</Text>
      </div>
      <Divider />
      <ScrollArea>
        <Table >
          <Table.Tbody>
            { orderProducts.length !== 0 && orderProducts.map(item => (
              <OrderRow key={ item.id } product={ item } />
            )) }
            { orderProducts.length === 0 && !loadingProducts &&
              <EmptyOrder />
            }
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Box>


  )
}

export default Summary