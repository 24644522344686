import { get_extras_by_product } from "../apis/extras";
import { get_product } from "../apis/products";

export const localOrder = async (products) => {
  const productTemp = [];

  for await (const product of products) {
    const { results } = await get_product(product._id);

    if (results && results[0]) {
      const productDetails = results[0];
      productDetails.quantity = product.quantity;
      productDetails.statusProductOrder = product.statusProductOrder;
      productDetails.note = product.note;

      productTemp.push({
        id: product.id,
        quantity: product.quantity,
        name: productDetails.name,
        statusProductOrder: product.statusProductOrder,
        note: product.note,
        price: product.isCourtesy ? 0 : productDetails.price,
        isCourtesy: product.isCourtesy,
        _id: product._id,
        extras: product.extras // Los extras procesados con quantity, name y price
      });
    }
  }

  console.log(productTemp);
  return productTemp;
};
