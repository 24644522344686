  export const get_setting= async () => {
  try {

    const url = `${process.env.REACT_APP_API_URL}/api/v1/settings`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

// export const post_setting = async (data) => {
//   try {
//     const url = `${process.env.REACT_APP_API_URL}/api/v1/settings`;
//     const params = {
//       method: 'POST',
//       headers: {
//         "Access-Control-Allow-Origin": "*",
//         "Content-Type": "application/json",
//         'Accept': 'application/json',
//         "Authorization": `Bearer ${token}`
//       },
//       body: JSON.stringify(data)
//     };

//     const response = await fetch(url, params);
//     const result = await response.json()
//     return result

//   } catch (error) {
//     console.log(error)
//     return null
//   }
// }

