const token =  localStorage.getItem('token')
export const get_banners = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/banners`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
export const get_gallery = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/gallery`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const get_slider = async (id) => {
  try {

    console.log(id)
    const url = `${process.env.REACT_APP_API_URL}/api/v1/uploads/${id}`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
      }
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
