import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from '../pages/Home/Home'
import Order from '../pages/Orders/Order'
import NotFound from "../pages/NotFound/NotFound";
import Header from "../components/Header/Header";


const AppRoute = () => {
  return (
    <>
   
      <Router>
      <Header />
        <Routes>
        <Route exact path="/" element={<Home />} /> 
          <Route exact path="/orders/:id" element={<Order />} /> 
          <Route path="*" element={<NotFound />} />

        </Routes>
      </Router>
    </>
  );
}

export default AppRoute;
