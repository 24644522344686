/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import settingReducer from './settingReducer';
import settingContext from './settingContext'
import
    {
        LIST_SETTINGS,

    } from '../types';
import { get_setting } from '../../utils/apis/settings';

const SattingState = props =>
{
    const initialState = {
        settings: [],
        loading: true
}

const [ state, dispatch ] = useReducer(settingReducer, initialState);

const getSettings = async () =>
{
    try {
        const res = await get_setting();
        dispatch({
            type: LIST_SETTINGS,
            payload: res.results
        })
    } catch (error) {

    }
}


return (
    <settingContext.Provider
        value={ {
            settings: state.settings,
            loading: state.loading,
            getSettings

        } }
    >
        {
            props.children
        }
    </settingContext.Provider>
)

}


export default SattingState
