import React from 'react'
import { Loader } from '@mantine/core'

const Loading = () =>
{
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <Loader
      />
    </div>
  )
}

export default Loading